import { createSlice } from '@reduxjs/toolkit'

// Define the initial state as a constant so it can be reused
const initialState = {
    id: null,
    user_role: '',
    country: {
        id: null,
        name: '',
        code: '',
        flag: '',
        phone_code: '',
    },
    timezone: {
        id: null,
        name: '',
        display_name: null,
        offset: '',
    },
    primary_language: {
        id: null,
        name: '',
        native: '',
        code: '',
        flag: null,
    },
    secondary_language: [],
    internal_name: '',
    name: '',
    description: {},
    domain: '',
    biz_id: '',
    status: '',
}

export const businessDataSlice = createSlice({
    name: 'businessData',
    initialState,
    reducers: {
        setBusinessData: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        resetBusinessData: () => {
            // Return the initial state to reset
            return initialState
        },
    },
})

// Export the actions
export const { setBusinessData, resetBusinessData } = businessDataSlice.actions

// Export the reducer
export default businessDataSlice.reducer
