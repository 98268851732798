import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEvent: (state, action) => {
            return {
                ...state,
                ...action.payload,
            }
        },
        // Reducer to reset the event state
        resetEvent: () => initialState,
    },
})

export const { setEvent, resetEvent } = eventSlice.actions
export default eventSlice.reducer
