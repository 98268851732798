import { createContext, useState, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { apiBusiness } from '@/services/ErlybrdApiService'
import { setAuthority, signOutSuccess, useAppDispatch } from '@/store'
import {
    resetBusinessData,
    setBusinessData,
} from '@/store/slices/business/businessDataSlice'
import { Loading } from '@/components/shared'

//initial state for business data
const initialState = {
    id: null,
    user_role: '',
    country: {
        id: null,
        name: '',
        code: '',
        flag: '',
        phone_code: '',
    },
    timezone: {
        id: null,
        name: '',
        display_name: null,
        offset: '',
    },
    currency: {
        id: null,
        name: '',
        code: '',
        symbol: '',
        decimal_digits: null,
    },
    primary_language: {
        id: null,
        name: '',
        native: '',
        code: '',
        flag: null,
    },
    secondary_language: [],
    internal_name: '',
    name: '',
    description: {},
    domain: '',
    biz_id: '',
    status: '',
}

// Context for Business Data
const BusinessContext = createContext()

// Hook to use Business Context
export const useBusiness = () => useContext(BusinessContext)

// Provider Component for Business Context
export const BusinessProvider = ({ children }) => {
    const [business, setBusiness] = useState(initialState)
    const [isLoading, setIsLoading] = useState(false) // Add this line
    const resetBusiness = () => setBusiness(initialState)

    return (
        <BusinessContext.Provider
            value={{
                business,
                setBusiness,
                isLoading,
                setIsLoading,
                resetBusiness,
            }}
        >
            {children}
        </BusinessContext.Provider>
    )
}

// Business Data Wrapper Component
export const BusinessWrapper = ({ children }) => {
    const { biz_id } = useParams()
    const { setBusiness, setIsLoading, resetBusiness } = useBusiness()
    const dispatch = useAppDispatch()

    const {
        data: businessData,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ['businessData', biz_id],
        queryFn: () => apiBusiness(`biz/${biz_id}/`),
        enabled: !!biz_id,
        gcTime: 0,
        staleTime: 0,
    })

    // check if user has permission to access this business
    if (isError) {
        if (
            error?.response?.data?.errors?.detail ===
            'You do not have permission to perform this action.'
        ) {
            dispatch(signOutSuccess())
            dispatch(resetBusinessData())
            resetBusiness()
        }
    }

    useEffect(() => {
        setIsLoading(isLoading)
    }, [isLoading, setIsLoading])

    useEffect(() => {
        if (businessData?.data?.id) {
            dispatch(setAuthority({ add: [businessData?.data?.user_role] }))
            dispatch(setBusinessData(businessData?.data))
            setBusiness(businessData?.data)
        }
    }, [businessData, setBusiness, dispatch])

    if (isLoading) {
        return (
            <div className="flex flex-auto flex-col h-[100vh]">
                <Loading loading={true} />
            </div>
        )
    }

    return children
}
