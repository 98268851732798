import * as Yup from 'yup'
import { isValidPhoneNumber } from 'libphonenumber-js'

Yup.addMethod(
    Yup.object,
    'notEmpty',
    function (message = 'This field cannot be empty') {
        return this.test('notEmpty', message, function (value) {
            const { path, createError } = this
            return (
                Object.keys(value).length > 0 || createError({ path, message })
            )
        })
    },
)

Yup.addMethod(Yup.string, 'phone', function (errorMessage) {
    return this.test('phone', errorMessage, (value) => {
        return !value || isValidPhoneNumber(value)
    })
})

export default Yup
