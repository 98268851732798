import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SLICE_BASE_NAME } from "./constants";
import { setEvent, resetEvent } from "../event/eventSlice";

export type UserState = {
	id: number | null;
	avatar?: string;
	userName?: string;
	email?: string;
	authority?: string[];
	first_name?: string;
	last_name?: string;
	is_staff?: boolean;
	groups?: string[];
	is_active?: boolean;
	is_superuser?: boolean;
	is_verified?: boolean;
	phone?: string;
	user_permissions?: string[];
	provider?: string;
	communication_language?: string | null;
};

const initialState: UserState = {
	id: null,
	avatar: "",
	userName: "",
	email: "",
	authority: [],
	first_name: "",
	last_name: "",
	is_staff: false,
	is_active: false,
	is_superuser: false,
	is_verified: false,
	groups: [],
	phone: "",
	user_permissions: [],
	provider: "",
	communication_language: null,
};

const userSlice = createSlice({
	name: `${SLICE_BASE_NAME}/user`,
	initialState,
	reducers: {
		setUser: (state, action: PayloadAction<UserState>) => {
			const payload = action?.payload;
			// Use for...in to iterate over the keys in the payload
			for (const key in payload) {
				if (Object.hasOwn(state, key)) {
					const value = payload[key];
					state[key] = value; // Update the state only for keys present in the payload
				}
			}
		},
		resetUser: () => {
			// Return the initial state to reset
			return initialState;
		},
		// setAuthority(state, action: PayloadAction<string[]>) {
		//     state.authority = action.payload
		// },
		// Update setAuthority to handle both adding and removing authorities
		setAuthority(
			state,
			action: PayloadAction<{ add?: string[]; remove?: string[] }>,
		) {
			const { add, remove } = action.payload;
			if (add) {
				// Add new authorities, avoiding duplicates
				for (const auth of add) {
					if (!state.authority?.includes(auth)) {
						state.authority?.push(auth);
					}
				}
			}
			if (remove) {
				// Remove specified authorities
				state.authority = state.authority?.filter(
					(auth) => !remove?.includes(auth),
				);
			}
		},
	},
	extraReducers(builder) {
		builder.addCase(setEvent, (state) => {
			// Add 'event' to authority if not already present
			if (!state.authority.includes("event")) {
				state.authority.push("event");
			}

			// Remove 'no_event' from authority
			state.authority = state.authority.filter((auth) => auth !== "no_event");
		});
		builder.addCase(resetEvent, (state) => {
			// Remove 'event' from authority
			state.authority = state.authority.filter((auth) => auth !== "event");

			// Add 'no_event' to authority if not already present
			if (!state.authority.includes("no_event")) {
				state.authority.push("no_event");
			}
		});
	},
});

export const { setUser, resetUser, setAuthority } = userSlice.actions;
export default userSlice.reducer;
