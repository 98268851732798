import { createSlice } from '@reduxjs/toolkit'

export const errorSlice = createSlice({
    name: 'error',
    initialState: {
        message: '',
        status: null,
    },
    reducers: {
        setGlobalError: (state, action) => {
            state.message = action.payload.message
            state.status = action.payload.status
        },
        clearGlobalError: (state) => {
            state.message = ''
            state.status = null
        },
    },
})

export const { setGlobalError, clearGlobalError } = errorSlice.actions
export default errorSlice.reducer
