// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import ar from './lang/ar.json'
import de from './lang/de.json'
import en from './lang/en.json'
import es from './lang/es.json'
import et from './lang/et.json'
import fi from './lang/fi.json'
import fr from './lang/fr.json'
import nl from './lang/nl.json'
import pt from './lang/pt.json'
import ro from './lang/ro.json'
import sl from './lang/sl.json'

import appConfig from '@/configs/app.config'

const resources = {
    ar: {
        translation: ar,
    },
    de: {
        translation: de,
    },
    en: {
        translation: en,
    },
    es: {
        translation: es,
    },
    et: {
        translation: et,
    },
    fi: {
        translation: fi,
    },
    fr: {
        translation: fr,
    },
    nl: {
        translation: nl,
    },
    pt: {
        translation: pt,
    },
    ro: {
        translation: ro,
    },
    sl: {
        translation: sl,
    },
}

i18n.use(initReactI18next).init({
    resources,
    fallbackLng: appConfig.locale,
    lng: appConfig.locale,
    interpolation: {
        escapeValue: false,
    },
})

export const dateLocales: {
    [key: string]: () => Promise<ILocale>
} = {
    ar: () => import('dayjs/locale/ar'),
    de: () => import('dayjs/locale/de'),
    en: () => import('dayjs/locale/en'),
    es: () => import('dayjs/locale/es'),
    et: () => import('dayjs/locale/et'),
    fi: () => import('dayjs/locale/fi'),
    fr: () => import('dayjs/locale/fr'),
    nl: () => import('dayjs/locale/nl'),
    pt: () => import('dayjs/locale/pt'),
    ro: () => import('dayjs/locale/ro'),
    sl: () => import('dayjs/locale/sl'),
}

export default i18n
