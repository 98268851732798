export const TOKEN_TYPE = 'Bearer'
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'
export const API_PATHS = {
    signIn: '/api/token/',
    socialSignIn: '/api/social-login/',
    signUp: '/api/user/create/',
    updateProfile: '/api/user/update/',
    forgotPassword: '/api/forgot-password/',
    refreshToken: '/api/token/refresh/',
    country: '/api/country/',
    city: '/api/city/',
    timezone: '/api/timezone/',
    currency: '/api/currency/',
    language: '/api/language/',
    roleList: '/api/role/',
    domainCheck: '/api/domain/check/',
    business: '/api/business/',
    venue: '/api/business/venue/',
    booking: '/api/booking/',
    redeem: '/api/redeemed/',
    branding: '/api/business/branding/',
    ipAddress: '/api/my-ip-address/',
    userManagement: '/api/business/users/',
    session: '/api/event-sessions/',
    ticket: '/api/ticket/',
    discount: '/api/discount/',
    event: '/api/event/',
    dashboard: '/api/dashboard/',
    media: '/api/media/',
    mediaSort: '/api/media-sort/',
    generateS3UploadUrl: '/api/generate-s3-url/',
    integration: '/api/integration/',
    integrated: '/api/integrated/',
    stripe: '/api/stripe-connect/',
    social: '/api/business/contact/',
    invite: '/api/invite/',
    createPassword: '/api/reset-password/',
    otpVerify: '/api/otp/verify/',
    otpResend: '/api/otp/resend/',
    serviceCharge: '/api/service/charge/',
    eventTicket: '/api/event-ticket/',
    coupon: '/api/coupons/',
    crm: '/api/crm/',
    downloadBooking: '/api/download/booking/',
    reports: '/api/reports/',
    notification: '/api/notification/',
    ownership: '/api/ownership/',
}
