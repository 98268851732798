import dayjs from 'dayjs'
import Yup from './yup_extensions'
import {
    getSignedUploadUrl,
    uploadFileToS3,
} from '@/services/ErlybrdApiService'
import { v4 as uuidv4 } from 'uuid'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import 'dayjs/locale/en-gb' // Importing British locale

dayjs.extend(customParseFormat)

function flattenObject(obj, parentKey = '', separator = '_') {
    const flattened = {}
    for (const key in obj) {
        if (Object.hasOwn(obj, key)) {
            const newKey = parentKey ? `${parentKey}${separator}${key}` : key
            if (
                typeof obj[key] === 'object' &&
                obj[key] !== null &&
                !Array.isArray(obj[key])
            ) {
                Object.assign(
                    flattened,
                    flattenObject(obj[key], newKey, separator),
                )
            } else {
                flattened[newKey] = obj[key]
            }
        }
    }

    if (Object.hasOwn(obj, 'user')) {
        const { first_name, last_name, email } = obj?.user ?? {}
        const name =
            `${first_name || ''} ${last_name || ''}`.trim() || email || ''
        // const role = obj?.role || ''
        // flattened.label = `${name} | ${role?.toUpperCase()}`
        flattened.label = `${name}`
        flattened.value = obj?.user?.id
    }

    return flattened
}

async function getCoordinates(address) {
    try {
        const res = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?key=${
                import.meta.env.VITE_GOOGLE_API_KEY
            }&address=${address}`,
        )
        const data = await res.json()
        const countryLatLng = data.results[0].geometry.location
        console.log(
            '🚀 ~ asyncgetCountryCoordinates ~ countryLatLng:',
            countryLatLng,
        )
        return countryLatLng
    } catch (error) {
        console.error(error)
    }
}

function calculateBookingDateTime({ date, time, value, type }) {
    // Combine date and time into a single string
    const parsedDate = dayjs(`${date} ${time}`)

    // Calculate the new date based on the type and value
    // -values is for negating because subtraction should always be done with positive values
    switch (type) {
        case 'dy':
            return value >= 0
                ? parsedDate.add(value, 'day')
                : parsedDate.subtract(-value, 'day')
        case 'hr':
            return value >= 0
                ? parsedDate.add(value, 'hour')
                : parsedDate.subtract(-value, 'hour')
        case 'min':
            return value >= 0
                ? parsedDate.add(value, 'minute')
                : parsedDate.subtract(-value, 'minute')

        default:
            throw new Error('Invalid type')
    }
}
function calculateDateDifference(main_date, target_date) {
    return dayjs(target_date).diff(dayjs(main_date), 'day')
}
function combineDateAndTime(timeString, dateString = null) {
    const dateTimeString = `${dateString || dayjs().format('YYYY-MM-DD')}T${timeString}`
    return dayjs(dateTimeString).toDate()
}

// Function to create a validation schema for a single field
const createFieldSchema = (primaryLanguage, secondaryLanguages) => {
    return Yup.object().shape({
        [primaryLanguage.code]: Yup.string().required('Required!'), // Primary language is required
        ...secondaryLanguages.reduce((acc, lang) => {
            acc[lang.code] = Yup.string() // Secondary languages are optional
            return acc
        }, {}),
    })
}

// Function to create initial values for a single field
const createFieldInitialValues = (languages) => {
    return languages.reduce((acc, lang) => {
        acc[lang.code] = ''
        return acc
    }, {})
}

function adjustNavPaths(navConfig, bizId) {
    const adjustPaths = (navItems) => {
        return navItems.map((item) => {
            // Replace :biz_id in the item path
            const updatedItem = {
                ...item,
                path: item.path.replace(':biz_id', bizId),
            }

            // Check if the item has a subMenu and adjust their paths
            if (updatedItem.subMenu && updatedItem.subMenu.length > 0) {
                updatedItem.subMenu = adjustPaths(updatedItem.subMenu)
            }

            return updatedItem
        })
    }
    const adjustedPaths = adjustPaths(navConfig)
    // console.log('🚀 ~ adjustNavPaths ~ adjustedPaths:', adjustedPaths)
    return adjustedPaths
}
async function uploadMedia({
    file,
    businessId,
    onSuccess,
    onProgress,
    onError,
}) {
    try {
        // Assuming `file` is the file object, `businessId` is the id of the business for which we're uploading
        const fileMimeType = file.type // e.g., image/png or video/mp4
        const fileMediaType = file.type.split('/')[0] // e.g., image, video
        const fileExtension = file.name.split('.').pop() // e.g., jpg, mp4, etc.

        // Get the signed URL for the media
        const { data: signedUrlData } = await getSignedUploadUrl(
            businessId,
            fileMimeType,
            fileExtension,
        )

        if (!signedUrlData.url) throw new Error('Failed to obtain signed URL')
        const fileUrl = signedUrlData.url + signedUrlData.fields.key
        const fileId = uuidv4()
        // Upload the media to S3 and track upload progress
        await uploadFileToS3(file, signedUrlData, (percentage) => {
            if (onProgress) onProgress({ percentage, fileId })
        })

        // If the upload is successful, call the onSuccess callback
        if (onSuccess)
            onSuccess({
                fileId,
                fileUrl,
                fileName: file.name,
                fileMimeType,
                fileMediaType,
                fileExtension,
            })
    } catch (error) {
        console.error('Error uploading file:', error)
        if (onError) onError(error)
    }
}

const getCdnUrl = (url, width, blur) => {
    const baseUrl = `${import.meta.env.VITE_CDN_URL}${url?.split('.com')?.[1]}`
    const widthParam = width ? `?tr=w-${width}` : ''
    const blurParam = blur ? `${width ? ',' : '?'}tr=bl-${blur}` : ''
    // const widthParam = width ? `?w=${width}` : "";
    // const blurParam = blur ? `${width ? "&" : "?"}blur=${blur}` : "";

    return `${baseUrl}${widthParam}${blurParam}`
}

export default function formatCurrency(
    value,
    currency,
    locale = navigator?.language,
) {
    try {
        if (value !== null && value !== undefined && currency && locale) {
            // Determine the decimal separator used in the current locale
            const decimalSeparator = new Intl.NumberFormat(locale)
                .format(1.1)
                .replace(/\d/g, '')
                .charAt(0)

            // Format the value as currency
            const formattedValue = new Intl.NumberFormat(locale, {
                style: 'currency',
                currency,
            }).format(value)

            // Construct a regex that removes everything except numbers, minus sign, and the locale's decimal separator
            const regex = new RegExp(`[^\\d${decimalSeparator}-]`, 'g')

            // Apply the regex to remove unwanted characters
            const cleanValue = formattedValue
                .replace(regex, '')
                .replace(decimalSeparator, '.')

            return cleanValue
        }
        // Return invalid empty string if the input is invalid
        if (typeof value !== 'number' && typeof value !== 'string') {
            return 'invalid value'
        }
        if (!currency) {
            // return 'invalid currency'
            return value
        }
        return 'invalid'
    } catch (error) {
        console.log('🚀 ~ error:', error)
    }
}

const mapBusinessPredictionsToOptions = (predictions) =>
    predictions.map((prediction) => ({
        placeId: prediction.place_id,
        value: prediction.structured_formatting.main_text,
        label: prediction.structured_formatting.main_text,
        address: prediction.structured_formatting.secondary_text,
    }))

const processCouponData = (rawData, currencyCode) => {
    return rawData?.map((coupon) => ({
        ...coupon,
        value: formatCurrency(coupon.value, currencyCode),
    }))
}

const processBookingData = (rawData, languageCode, currencyCode) => {
    const channelMappings = {
        true: 'DIRECT',
        false: 'ERLYBRD',
    }

    return rawData?.map((booking) => {
        const isMultipleTickets = booking?.booking_data?.length > 1

        const processTicket = (ticket) => ({
            ticket_name: ticket?.ticket?.name?.[languageCode],
            booked_on: ticket?.created_at,
            booked_for: ticket?.ticket_date,
            price: formatCurrency(ticket?.ticket_price, currencyCode),
            redeemed: ticket?.redeemed,
            redeemed_at: ticket?.redeemed
                ? dayjs(ticket?.redeemed_at).format('MMM D, YYYY hh:mm A')
                : '--',
            redeemed_by: ticket?.redeemed
                ? `${ticket?.redeemed_by?.first_name} ${ticket?.redeemed_by?.last_name}`
                : '--',
            redeemed_platform: ticket?.redeemed
                ? ticket?.redeemed_platform
                : '--',
        })

        if (isMultipleTickets) {
            return {
                customer: booking?.user,
                event_name: booking?.event?.internal_name,
                ticket_name: `Quantity: ${booking?.booking_data?.length}`,
                booked_on: booking?.booking_data?.[0]?.created_at,
                booked_for: booking?.booking_data?.[0]?.ticket_date,
                price: formatCurrency(booking?.total_price, currencyCode),
                channel: channelMappings[booking?.is_direct] || null,
                subRows: booking?.booking_data.map(processTicket),
            }
        }

        return {
            customer: booking?.user,
            event_name: booking?.event?.internal_name,
            channel: channelMappings[booking?.is_direct] || null,
            ...processTicket(booking?.booking_data?.[0]),
            subRows: null,
        }
    })
}

const processScanData = (data, languageCode) => {
    return {
        user: data?.booking?.user,
        event_name: data?.booking?.event?.internal_name,
        ticket_name: data?.ticket?.name?.[languageCode],
        price: formatCurrency(
            data?.ticket_price,
            data?.booking?.currency?.code,
        ),
        booked_on: dayjs(data?.created_at).format('MMM D, YYYY hh:mm A'),
        booked_for: dayjs(data?.ticket_date).format('MMM D, YYYY hh:mm A'),
        redeemed: data?.redeemed,
        redeemed_at: data?.redeemed
            ? dayjs(data?.redeemed_at).format('MMM D, YYYY hh:mm A')
            : '--',
        redeemed_by: data?.redeemed
            ? `${data?.redeemed_by?.first_name} ${data?.redeemed_by?.last_name}`
            : '--',
        redeemed_platform: data?.redeemed ? data?.redeemed_platform : '--',
    }
}

function removeSocialPrefixes(socialData) {
    return Object.keys(socialData).reduce((cleanedData, key) => {
        const value = socialData[key]
        // Correctly escape the dot and use double backslashes for the RegExp string
        const urlPattern = new RegExp(`^https://(${key}\\.com/)?`)

        if (typeof value === 'string' && value.startsWith('https://')) {
            cleanedData[key] = value.replace(urlPattern, '')
        } else {
            cleanedData[key] = value
        }

        return cleanedData
    }, {})
}

const processSocialDataToSend = (values) => {
    return Object.keys(values).reduce((acc, key) => {
        if (
            [
                'website',
                'privacy_policy',
                'refund_policy',
                'terms_of_service',
            ].includes(key)
        ) {
            // Only add https:// if the field is not empty and doesn't already start with http:// or https://
            acc[key] =
                values[key] &&
                !values[key].startsWith('http://') &&
                !values[key].startsWith('https://')
                    ? `https://${values[key]}`
                    : values[key]
        } else if (
            [
                'facebook',
                'twitter',
                'instagram',
                'linkedin',
                'youtube',
                'tiktok',
            ].includes(key)
        ) {
            // For social media fields, prefix with the platform's URL if not empty
            acc[key] = values[key] ? `https://${key}.com/${values[key]}` : ''
        } else {
            // Leave email and phone as is
            acc[key] = values[key]
        }
        return acc
    }, {})
}

// function processSalesDataForChart(apiResponse, primaryLanguageCode) {
//     const salesReportData = {
//         series: [],
//         categories: [],
//     }

//     // Initialize a map to track event data
//     const eventsData = new Map()

//     // First, fill the categories with for...of
//     for (const entry of apiResponse) {
//         const formattedDate = dayjs(entry.date).format('DD MMM')
//         if (!salesReportData.categories.includes(formattedDate)) {
//             salesReportData.categories.push(formattedDate)
//         }
//     }

//     // Then process each event, knowing the categories are complete, with for...of
//     for (const entry of apiResponse) {
//         const formattedDate = dayjs(entry.date).format('DD MMM')
//         for (const event of entry.event) {
//             const eventName =
//                 event?.internal_name ||
//                 event?.name?.[primaryLanguageCode] ||
//                 event?.name?.en ||
//                 'event'
//             if (!eventsData.has(eventName)) {
//                 eventsData.set(
//                     eventName,
//                     new Array(salesReportData.categories.length).fill(0),
//                 )
//             }
//             const eventIndex = salesReportData.categories.indexOf(formattedDate)
//             // Update only if eventIndex is valid
//             if (eventIndex !== -1) {
//                 eventsData.get(eventName)[eventIndex] += event.count
//             }
//         }
//     }

//     // Convert the map to the series format with for...of
//     for (const [name, data] of eventsData) {
//         salesReportData.series.push({ name, data })
//     }

//     return salesReportData
// }

function processSalesDataForChart(
    apiResponse,
    primaryLanguageCode,
    currencyCode,
) {
    const salesReportData = {
        series: [],
        categories: [],
    }

    // Initialize a map to track event data
    const eventsData = new Map()

    // Fill the categories with formatted dates
    for (const entry of apiResponse) {
        const formattedDate = dayjs(entry.date).format('DD MMM')
        if (!salesReportData.categories.includes(formattedDate)) {
            salesReportData.categories.push(formattedDate)
        }
    }

    // Process each event, now that the categories are complete
    for (const entry of apiResponse) {
        const formattedDate = dayjs(entry.date).format('DD MMM')
        for (const event of entry.event) {
            const eventName =
                event.name[primaryLanguageCode] || event.name.en || 'event'
            if (!eventsData.has(eventName)) {
                // Initialize a new array for both count and amount
                eventsData.set(eventName, {
                    counts: new Array(salesReportData.categories.length).fill(
                        0,
                    ),
                    amounts: new Array(salesReportData.categories.length).fill(
                        0,
                    ),
                })
            }
            const eventIndex = salesReportData.categories.indexOf(formattedDate)
            if (eventIndex !== -1) {
                // Update count and amount
                eventsData.get(eventName).counts[eventIndex] += event.count
                eventsData.get(eventName).amounts[eventIndex] += event.amount
            }
        }
    }

    // Convert the map to the series format
    for (const [name, data] of eventsData) {
        salesReportData.series.push({
            name,
            data: data.counts.map((count, index) => ({
                x: salesReportData.categories[index],
                y: count,
                // totalAmount: `${formatCurrency(data.amounts[index], currencyCode)} ${currencyCode}`,
                totalAmount: formatCurrency(data.amounts[index], currencyCode),
            })),
        })
    }

    return salesReportData
}

function flagEmojiToLetters(flagEmoji) {
    if (!flagEmoji) return
    const base = 127462 // Base code point for regional indicator symbol letter A
    let letters = ''

    for (let i = 0; i < flagEmoji.length; i++) {
        const code = flagEmoji.codePointAt(i) - base
        if (code >= 0 && code <= 25) {
            // Ensure it's a regional indicator symbol
            letters += String.fromCharCode(65 + code)
        }
        i++ // Increase by 1 to skip the low surrogate of the surrogate pair
    }

    return letters
}

const processCrmAmountData = (rawData, currencyCode) => {
    return {
        ...rawData,
        total_amount: formatCurrency(rawData.total_amount, currencyCode),
        total_discount: formatCurrency(rawData.total_discount, currencyCode),
        total_coupon_discount: formatCurrency(
            rawData.total_coupon_discount,
            currencyCode,
        ),
    }
}

const processCrmData = (rawData, currencyCode) => {
    return rawData?.map(({ summary, ...others }) =>
        processCrmAmountData(
            {
                ...others,
                ...summary,
                name: `${others.first_name} ${others.last_name}`,
            },
            currencyCode,
        ),
    )
}

const processCrmUserData = (rawData, currencyCode) => {
    const { user, summary, booking } = rawData || {}
    return {
        ...processCrmAmountData(
            {
                ...user,
                ...summary,
                name: `${user.first_name} ${user.last_name}`,
            },
            currencyCode,
        ),
        booking: booking.map((data) =>
            processCrmAmountData(data, currencyCode),
        ),
    }
}

function processErrorMessage(response, customErrorMessage = '') {
    return (
        response?.data?.msg ||
        response?.data?.error ||
        response?.data?.email ||
        response?.data?.event ||
        response?.data?.coupon ||
        response?.data?.message ||
        response?.data?.event_session ||
        response?.data?.errors?.detail ||
        response?.data?.errors?.email ||
        response?.data?.errors?.domain ||
        JSON.stringify(response?.data?.errors) ||
        customErrorMessage ||
        'Something Went Wrong!'
    )
}

const getFormattedDate = (value) => dayjs(value).format('YYYY-MM-DD')
const getDateObject = (value) => dayjs(value).toDate()

const handleDateRangeChange = ([start, end]) => {
    if (start && end) {
        return [getFormattedDate(start), getFormattedDate(end)]
    }
    return ['', '']
}

const handleDateRangeValue = ([start, end]) => {
    if (start && end) {
        return [getDateObject(start), getDateObject(end)]
    }
    return ['', '']
}

const formatDateRange = (start, end) => [
    start.format('YYYY-MM-DD'),
    end.format('YYYY-MM-DD'),
]

const getDateRange = (optionValue) => {
    switch (optionValue) {
        case 'all_time':
            return [null, null]
        case 'today':
            return formatDateRange(dayjs(), dayjs())
        case 'yesterday':
            return formatDateRange(
                dayjs().subtract(1, 'day'),
                dayjs().subtract(1, 'day'),
            )
        case 'this_week': {
            const localDayjs = dayjs().locale('en-gb')
            return formatDateRange(
                localDayjs.startOf('week'),
                localDayjs.endOf('week'),
            )
        }
        case 'this_month':
            return formatDateRange(
                dayjs().startOf('month'),
                dayjs().endOf('month'),
            )
        case 'this_year':
            return formatDateRange(
                dayjs().startOf('year'),
                dayjs().endOf('year'),
            )
        case 'last_7_days':
            return formatDateRange(dayjs().subtract(7, 'day'), dayjs())
        case 'last_30_days':
            return formatDateRange(dayjs().subtract(30, 'day'), dayjs())
        case 'last_90_days':
            return formatDateRange(dayjs().subtract(90, 'day'), dayjs())
        default:
            // return [null, null]
            return optionValue?.split(',')
    }
}

const processCouponReportData = (rawData, currencyCode) => {
    return rawData?.map((data) => ({
        ...data,
        applied_at: dayjs(data?.created_at).format('MMM D, YYYY hh:mm A'),
        amount: formatCurrency(data.amount, currencyCode),
        booking_amount: formatCurrency(data.booking_amount, currencyCode),
    }))
}

const processScanReportData = (rawData) => {
    return rawData?.map((data) => ({
        ...data,
        // scanned_on: data?.created_at,
        scanned_on: dayjs(data?.created_at).format('MMM D, YYYY'),
        event: data?.event?.internal_name,
    }))
}

export {
    dayjs,
    getCdnUrl,
    uploadMedia,
    getDateRange,
    adjustNavPaths,
    flattenObject,
    processCouponData,
    processCouponReportData,
    processScanReportData,
    getCoordinates,
    formatCurrency,
    processErrorMessage,
    handleDateRangeChange,
    handleDateRangeValue,
    processScanData,
    flagEmojiToLetters,
    removeSocialPrefixes,
    processSocialDataToSend,
    processCrmData,
    processCrmUserData,
    calculateBookingDateTime,
    calculateDateDifference,
    processBookingData,
    combineDateAndTime,
    createFieldSchema,
    createFieldInitialValues,
    processSalesDataForChart,
    mapBusinessPredictionsToOptions,
}
