import { clearGlobalError } from '@/store/slices/error/errorSlice'
import toast from '@/components/ui/toast'
import Notification from '@/components/ui/Notification'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '@/store'

export default function GlobalErrorHandler({ children }) {
    const dispatch = useAppDispatch()
    const errorMessage = useAppSelector((state) => state.error.message)
    useEffect(() => {
        if (errorMessage) {
            toast.push(
                <Notification type="danger" title="Message" width="w-fit">
                    {errorMessage}
                </Notification>,
                {
                    placement: 'top-center',
                },
            )
            dispatch(clearGlobalError())
        }
    }, [errorMessage])
    return children
}
