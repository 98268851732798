import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filters: {
        dateRange: 'last_30_days',
    },
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setDashboardFilters: (state, action) => {
            state.filters = action.payload
        },
    },
})

// Export the actions
export const { setDashboardFilters } = dashboardSlice.actions

// Export the reducer
export default dashboardSlice.reducer
