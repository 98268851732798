import { useEffect } from 'react'

const AnalyticsScripts = () => {
    useEffect(() => {
        if (import.meta.env.VITE_ENV === 'prod') {
            // Google Analytics script
            const gaScript = document.createElement('script')
            gaScript.async = true
            gaScript.src =
                'https://www.googletagmanager.com/gtag/js?id=G-G0CLLZTYSS'
            document.head.appendChild(gaScript)

            gaScript.onload = () => {
                window.dataLayer = window.dataLayer || []
                const gtag = (...args) => dataLayer.push(args)
                gtag('js', new Date())
                gtag('config', 'G-G0CLLZTYSS')
            }

            // Microsoft Clarity script
            const clarityScript = document.createElement('script')
            clarityScript.type = 'text/javascript'
            clarityScript.innerHTML = `
        (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
          t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/" + i;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "k5t2yhf8yt");
      `
            document.head.appendChild(clarityScript)
        }
    }, [])

    return null // This component does not render anything itself
}

export default AnalyticsScripts
