import {
    apiOtpResend,
    apiOtpVerify,
    apiSignIn,
    apiSignUp,
    apiSocialSignIn,
} from '@/services/AuthService'
import {
    setUser,
    signInSuccess,
    signOutSuccess,
    useAppSelector,
    useAppDispatch,
    setTempCredentials,
    clearTempCredentials,
    resetUser,
} from '@/store'
import appConfig from '@/configs/app.config'
import { REDIRECT_URL_KEY } from '@/constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { resetBusinessData } from '@/store/slices/business/businessDataSlice'
import { resetEvent } from '@/store/slices/event/eventSlice'
import { useBusiness } from '@/Custom/BusinessContext'

function useAuth() {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const query = useQuery()
    const { resetBusiness } = useBusiness()
    const { accessToken, signedIn, otpSecret, tempEmail, tempPassword } =
        useAppSelector((state) => state.auth.session)

    const socialSignIn = async (values) => {
        try {
            const { data } = await apiSocialSignIn(values)
            console.log('🚀 ~ socialSignIn ~ data:', data)
            if (data?.id) {
                const {
                    access: accessToken,
                    refresh: refreshToken,
                    first_name,
                    last_name,
                    email,
                    id,
                    is_staff,
                    is_active,
                    is_superuser,
                    is_verified,
                    groups,
                    phone,
                    user_permissions,
                    provider,
                    communication_language,
                } = data
                dispatch(signInSuccess({ accessToken, refreshToken }))
                dispatch(
                    setUser({
                        id: id,
                        userName: first_name || 'Anonymous',
                        authority: ['no_event'],
                        first_name,
                        last_name,
                        email,
                        is_staff,
                        is_active,
                        is_superuser,
                        is_verified,
                        groups,
                        phone,
                        user_permissions,
                        provider,
                        communication_language,
                    }),
                )

                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl
                        ? redirectUrl
                        : appConfig.authenticatedEntryPath,
                )

                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signIn = async (values) => {
        try {
            const { data } = await apiSignIn(values)
            console.log('🚀 ~ signIn ~ data:', data)
            if (data?.otp_verified === false) {
                dispatch(
                    setTempCredentials({
                        tempEmail: values.email,
                        tempPassword: values.password,
                        otpSecret: data?.otp_secret,
                    }),
                )
                navigate('/otp')
            }
            if (data?.id) {
                const {
                    access: accessToken,
                    refresh: refreshToken,
                    first_name,
                    last_name,
                    email,
                    id,
                    is_staff,
                    is_active,
                    is_superuser,
                    is_verified,
                    groups,
                    phone,
                    user_permissions,
                    provider,
                    communication_language,
                } = data
                dispatch(signInSuccess({ accessToken, refreshToken }))
                dispatch(
                    setUser({
                        id: id,
                        userName: first_name || 'Anonymous',
                        authority: ['no_event'],
                        first_name,
                        last_name,
                        email,
                        is_staff,
                        is_active,
                        is_superuser,
                        is_verified,
                        groups,
                        phone,
                        user_permissions,
                        provider,
                        communication_language,
                    }),
                )

                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl
                        ? redirectUrl
                        : appConfig.authenticatedEntryPath,
                )

                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const { data } = await apiSignUp(values)
            if (data?.status === 'ok') {
                dispatch(
                    setTempCredentials({
                        tempEmail: values.email,
                        tempPassword: values.password,
                        otpSecret: data?.user?.otp_secret,
                    }),
                )
                navigate('/otp')
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const otpVerify = async ({ otp }) => {
        try {
            const {
                data: { otp_verified },
            } = await apiOtpVerify({ otp, otp_secret: otpSecret })
            if (otp_verified) {
                await signIn({
                    email: tempEmail,
                    password: tempPassword,
                })
                dispatch(clearTempCredentials())
            }
        } catch (errors) {
            console.error('🚀 ~ otpVerify ~ errors:', errors)
        }
    }

    const otpResend = async () => {
        try {
            const { data } = await apiOtpResend({ otp_secret: otpSecret })
            console.log('🚀 ~ otpResend ~ data:', data)
            if (data?.otp_secret) {
                dispatch(
                    setTempCredentials({
                        tempEmail: tempEmail,
                        tempPassword: tempPassword,
                        otpSecret: data?.otp_secret,
                    }),
                )
            }
        } catch (error) {
            console.error('🚀 ~ otpResend ~ error:', error)
        }
    }

    const handleSignOut = () => {
        dispatch(signOutSuccess())
        dispatch(resetEvent())
        dispatch(resetUser())
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        // await apiSignOut()
        handleSignOut()
        dispatch(resetBusinessData())
        resetBusiness()
    }

    return {
        authenticated: accessToken && signedIn,
        signIn,
        socialSignIn,
        signUp,
        signOut,
        otpVerify,
        otpResend,
    }
}

export default useAuth
