import { API_PATHS } from '@/constants/api.constant'
import ApiService from './ApiService'

import type {
    SignInCredential,
    SignUpCredential,
    ForgotPassword,
    ResetPassword,
    SignInResponse,
    SignUpResponse,
} from '@/@types/auth'
import axios from 'axios'
import appConfig from '@/configs/app.config'

// export async function apiSignIn(data: SignInCredential) {
//     return ApiService.fetchData<SignInResponse>({
//         url: '/sign-in',
//         method: 'post',
//         data,
//     })
// }

// export async function apiSignUp(data: SignUpCredential) {
//     return ApiService.fetchData<SignUpResponse>({
//         url: '/sign-up',
//         method: 'post',
//         data,
//     })
// }

export async function apiSignOut() {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
    })
}

export async function apiForgotPassword(data: ForgotPassword) {
    return ApiService.fetchData({
        url: `${API_PATHS.forgotPassword}?email=${data.email}`,
        method: 'get',
    })
}

export async function apiResetPassword(data: ForgotPassword) {
    const { token, password } = data
    return ApiService.fetchData({
        url: API_PATHS.forgotPassword,
        method: 'patch',
        data: { password },
        headers: {
            Authorization: token,
        },
    })
}

export async function apiUpdateProfile(id, data) {
    return ApiService.fetchData({
        url: `${API_PATHS.updateProfile}${id}/`,
        method: 'patch',
        data,
    })
}

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: API_PATHS.signIn,
        method: 'post',
        data,
    })
}

export async function apiSocialSignIn(data) {
    return ApiService.fetchData({
        url: API_PATHS.socialSignIn,
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: API_PATHS.signUp,
        method: 'post',
        data,
    })
}
export async function apiInvite(url = '', method = 'get', data = null) {
    return ApiService.fetchData({
        url: API_PATHS.invite + url,
        method,
        data,
    })
}

export async function apiRefreshToken(data) {
    return axios.post(`${appConfig.apiPrefix}${API_PATHS.refreshToken}`, data)
}

export async function apiOtpVerify(data) {
    return ApiService.fetchData({
        url: API_PATHS.otpVerify,
        method: 'post',
        data,
    })
}

export async function apiOtpResend(data) {
    return ApiService.fetchData({
        url: API_PATHS.otpResend,
        method: 'post',
        data,
    })
}
