import axios from 'axios'
import appConfig from '@/configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from '@/constants/api.constant'
import { PERSIST_STORE_NAME } from '@/constants/app.constant'
import deepParseJson from '@/utils/deepParseJson'
import store, { setAccessToken, signOutSuccess } from '../store'
import { setGlobalError } from '../store/slices/error/errorSlice'
import { apiRefreshToken } from './AuthService'
import { processErrorMessage } from '@/Custom/helper'

const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

const dispatchGlobalError = ({ message, status }) => {
    store.dispatch(setGlobalError({ message, status }))
}

BaseService.interceptors.request.use(
    (config) => {
        // for apis that do not require authentication
        if (config.isAuthRequired === false) return config

        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let accessToken = (persistData as any).auth.session.accessToken

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.accessToken
        }

        if (accessToken) {
            config.headers[REQUEST_HEADER_AUTH_KEY] =
                `${TOKEN_TYPE} ${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)

BaseService.interceptors.response.use(
    (response) => response,
    async (error) => {
        console.log('BSIE', error)
        const { response, config } = error
        if (response) {
            if (unauthorizedCode.includes(response.status)) {
                console.log('tokenExpired', config)

                const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
                const persistData = deepParseJson(rawPersistData)
                const { refreshToken } = persistData.auth.session

                if (!config._retry && refreshToken) {
                    config._retry = true
                    try {
                        // Replace with your refresh token endpoint and method
                        const refreshResponse = await apiRefreshToken({
                            refresh: refreshToken,
                        })
                        const newAccessToken = refreshResponse?.data?.access
                        // console.log('newAccessToken', newAccessToken)
                        if (newAccessToken) {
                            store.dispatch(setAccessToken(newAccessToken)) // Update token in store
                            // Update token in request header
                            config.headers[REQUEST_HEADER_AUTH_KEY] =
                                `${TOKEN_TYPE} ${newAccessToken}`
                            // Retry with the new token
                            return axios(config)
                        }
                    } catch (refreshError) {
                        // Handle failed refresh here (e.g., redirect to login)
                        store.dispatch(signOutSuccess())
                        return Promise.reject(refreshError)
                    }
                }
                // If refresh token is not available or retry has already been done, dispatch sign out
                store.dispatch(signOutSuccess())
                return Promise.reject(error) // Reject the original error
            }
            // only showing the error message if the status code is not 401
            dispatchGlobalError({
                message: processErrorMessage(response),
                status: response.status,
            })
        } else {
            // Handle network errors
            dispatchGlobalError({
                message: 'Network Error',
                status: 'NETWORK_ERROR',
            })
        }

        return Promise.reject(error)
    },
)

export default BaseService
