import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    filters: {
        bookedFor: '',
        bookedOn: '',
        redeemed: '',
        channel: '',
        event: [],
    },
}

export const bookingSlice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setBookingFilters: (state, action) => {
            state.filters = action.payload
        },
        resetBookingFilters: (state) => {
            state.filters = initialState.filters
        },
    },
})

// Export the actions
export const { setBookingFilters, resetBookingFilters } = bookingSlice.actions

// Export the reducer
export default bookingSlice.reducer
