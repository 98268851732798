import { createSlice } from '@reduxjs/toolkit'
import { SLICE_BASE_NAME } from './constants'

export interface SessionState {
    signedIn: boolean
    accessToken: string | null
    refreshToken: string | null
    tempEmail: string | null
    tempPassword: string | null
    otpSecret: string | null
}

const initialState: SessionState = {
    signedIn: false,
    accessToken: null,
    refreshToken: null,
    tempEmail: null,
    tempPassword: null,
    otpSecret: null,
}

const sessionSlice = createSlice({
    name: `${SLICE_BASE_NAME}/session`,
    initialState,
    reducers: {
        setTempCredentials: (state, action) => {
            state.otpSecret = action.payload.otpSecret
            state.tempEmail = action.payload.tempEmail
            state.tempPassword = action.payload.tempPassword
        },
        clearTempCredentials: (state) => {
            state.otpSecret = null
            state.tempEmail = null
            state.tempPassword = null
        },
        signInSuccess(state, action) {
            state.signedIn = true
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        },
        signOutSuccess(state) {
            state.signedIn = false
            state.accessToken = null
            state.refreshToken = null
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
    },
})

export const {
    signInSuccess,
    signOutSuccess,
    setAccessToken,
    setTempCredentials,
    clearTempCredentials,
} = sessionSlice.actions
export default sessionSlice.reducer
